export const scrollToBlock = (targetElement, dur = 2000, offset = 0) => {
  const scrollDown = (targetPosition, duration) => {
    const startPosition = window.pageYOffset || window.scrollY
    let startTime = null

    function animation(currentTime) {
      if (startTime === null) startTime = currentTime
      const timeElapsed = currentTime - startTime
      const run = ease(timeElapsed, startPosition, targetPosition, duration)
      window.scrollTo(0, run)
      if (timeElapsed < duration) requestAnimationFrame(animation)
    }

    function ease(t, b, c, d) {
      // Функция easeInOutCubic для плавной анимации
      t /= d / 2
      if (t < 1) return (c / 2) * t * t * t + b
      t -= 2
      return (c / 2) * (t * t * t + 2) + b
    }

    requestAnimationFrame(animation)
  }

  setTimeout(() => {
    // const target = document.getElementById(val)!.getBoundingClientRect().top + window.scrollY
    // const targetElement = document.getElementById(val);
    const addressBarHeight = window.innerHeight - document.documentElement.clientHeight
    const targetPosition = targetElement.getBoundingClientRect().top - offset - (addressBarHeight || 0)
    if (targetPosition) scrollDown(targetPosition, dur)
  }, 1)
}
